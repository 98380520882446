<script setup lang="ts">
import { useChecklistStore } from '@/stores/views/Optimization/Checklist'
import { defineProps, watch, ref } from 'vue'
import { Task } from '@aedifion.io/aedifion-api'
import SavingTasksTable from './SavingTasksTable.vue'
import SavingsDetailsTable from './SavingDetailsTable.vue'

const checklistStore = useChecklistStore()

interface Props {
  // TODO: Add type for saving
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saving: any
}

const props = defineProps<Props>()
const filteredTasks = ref<Task[]>([])

watch(() => props.saving, () => {
  refreshTasks()
})

function filterTasks () {
  if (props.saving && checklistStore.tasks?.items!.length) {
    const functionId = props.saving.analysis.custom.function_id
    const componentInProjectId = props.saving.component.component_id
    const tasksItems = checklistStore.tasks?.items
    const filteredItems = tasksItems.filter((task) => {
      return task.analytics_result![0]?.function_id.toString() === functionId && task.componentinproject![0]?.component_id?.toString() === componentInProjectId
    })

    filteredTasks.value.push(...filteredItems!)
  }
}

async function refreshTasks () {
  filteredTasks.value = []

  filterTasks()
}

refreshTasks()
</script>

<template>
  <SavingsDetailsTable
    v-if="props.saving"
    :saving="saving"
  />
  <SavingTasksTable
    :tasks="filteredTasks"
  />
</template>
