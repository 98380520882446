<script setup lang="ts">
import PageHeader from '@/components/PageHeader.vue'
import SavingsTable from './SavingsTable.vue'
import { useI18n } from 'vue-i18n'
import { useSavingsStore } from '@/stores/views/Savings'
import { storeToRefs } from 'pinia'
import SidePeek from '@/components/Layout/SidePeek.vue'
import { ref, onMounted, watch } from 'vue'
import SavingDetailsView from './SavingDetails/SavingDetailsView.vue'
import DefaultPageContainer from '@/components/Layout/DefaultPageContainer.vue'
import { useAppStore } from '@/stores/app'
import { useChecklistStore } from '@/stores/views/Optimization/Checklist'

const { t } = useI18n()

const appStore = useAppStore()
const checklistStore = useChecklistStore()
const savingsStore = useSavingsStore()
const { savingsTableRows } = storeToRefs(savingsStore)

const isSidePeekOpen = ref(false)

// TODO: Add type for saving
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const selectedSaving = ref<any>(() => null)

function closeSidePeek () {
  isSidePeekOpen.value = false
}

function handleOutSideClick () {
  closeSidePeek()
}

onMounted(() => {
  checklistStore.getTasks({ projectId: appStore.projectId })
})

watch(() => appStore.projectId, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    checklistStore.getTasks({ projectId: appStore.projectId })
  }
})

// TODO: Add type for saving
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function handleClickSaving (saving: any) {
  selectedSaving.value = saving
  isSidePeekOpen.value = true
}
</script>

<template>
  <SidePeek
    class="tw-h-screen"
    :is-open="isSidePeekOpen"
    rounded
    :ignore-outside-click-on-classes="['.savings-table']"
    @outside-click="handleOutSideClick"
    @close="closeSidePeek()"
  >
    <template #topBar>
      <v-card-subtitle class="text-subtitle-2 pl-6 pt-4 pb-0">
        {{ selectedSaving.component?.subtitle }}
      </v-card-subtitle>
      <v-card-title class="pl-6 pt-0 pb-4">
        {{ selectedSaving.component?.text }}
      </v-card-title>
    </template>
    <template #actionButtons>
      <v-tooltip
        :text="t('delete_saving')"
        location="bottom"
        content-class="aedifion-tooltip text-neutral-darken3 tw-max-w-[249px]"
      >
        <template
          #activator="{ props: deleteSavingProps }"
        >
          <v-btn
            class="tw-m-3 saving-action-button"
            density="compact"
            v-bind="deleteSavingProps"
            icon
            size="small"
            :rounded="true"
          >
            <v-icon
              class="text-neutral-darken3"
              size="14"
            >
              fa:far fa-trash
            </v-icon>
          </v-btn>
        </template>
      </v-tooltip>
      <v-tooltip
        :text="t('edit_saving')"
        location="bottom"
        content-class="aedifion-tooltip text-neutral-darken3 tw-max-w-[249px]"
      >
        <template
          #activator="{ props: editSavingProps }"
        >
          <v-btn
            class="tw-m-3 saving-action-button"
            density="compact"
            icon
            v-bind="editSavingProps"
            size="small"
            :rounded="true"
          >
            <v-icon
              class="text-neutral-darken3"
              size="14"
            >
              fa:far fa-pen-clip
            </v-icon>
          </v-btn>
        </template>
      </v-tooltip>
      <v-tooltip
        :text="t('copy_link')"
        location="bottom"
        content-class="aedifion-tooltip text-neutral-darken3 tw-max-w-[249px]"
      >
        <template
          #activator="{ props: copySavingLinkProps }"
        >
          <v-btn
            class="tw-m-3 saving-action-button"
            density="compact"
            icon
            v-bind="copySavingLinkProps"
            size="small"
            :rounded="true"
          >
            <v-icon
              class="text-neutral-darken3"
              size="14"
            >
              fa:far fa-link-horizontal
            </v-icon>
          </v-btn>
        </template>
      </v-tooltip>
    </template>
    <div
      class="tw-bg-[rgb(var(--v-theme-neutral-lighten5))]"
    >
      <SavingDetailsView
        :saving="selectedSaving"
      />
    </div>
    <template #pageContent>
      <DefaultPageContainer>
        <div
          class="layout-wrapper"
        >
          <PageHeader
            sticky
            title-key="links.meta.title.savings"
          >
            <v-btn
              elevation="auto"
              class="aedifion-border aedifion-shadow-drop tw-ml-auto"
            >
              <span class="tw-mr-2">{{ t('add_saving') }}</span>
              <v-icon size="14">
                fa:far fa-plus
              </v-icon>
            </v-btn>
          </PageHeader>
          <SavingsTable
            class="savings-table"
            :loading="false"
            :rows="savingsTableRows"
            :reset-selection="!isSidePeekOpen"
            @click-saving="handleClickSaving"
          />
        </div>
      </DefaultPageContainer>
    </template>
  </SidePeek>
</template>

<style lang="sass" scoped>
  .saving-action-button:hover
    border: 1px solid rgb(var(--v-theme-neutral))
</style>

<i18n lang="json" locale="de">
  {
    "add_saving": "Sparung hinzufügen",
    "delete_saving": "Gesamte Einsparung löschen",
    "edit_saving": "Einsparung bearbeiten",
    "copy_link": "Link zur Einsparung kopieren"
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "add_saving": "Add saving",
    "delete_saving": "Delete entire saving",
    "edit_saving": "Edit saving",
    "copy_link": "Copy link to saving"
  }
</i18n>
