<script setup lang="ts">
import { Task } from '@aedifion.io/aedifion-api'
import { useI18n } from 'vue-i18n'
import { getPriorityColor, getStatusColor, getStatusBorderColor, getStatusTextColor } from '@/utils/helpers/tasks'
import PriorityIcon from '@/components/TaskList/PriorityIcon.vue'
import { useAppStore } from '@/stores/app'

const { t } = useI18n()
const appStore = useAppStore()

defineProps<{
  tasks: Task[]
}>()
</script>

<template>
  <v-container class="tw-flex tw-flex-col saving-tasks">
    <v-row class="table-row">
      <v-col
        class="text-neutral-darken1 font-size-12"
        data-testid="contributing-tasks"
      >
        {{ t('contributing_tasks') }}
      </v-col>
    </v-row>
    <div
      v-if="tasks.length === 0"
      class="tw-text-center mt-6 no-tasks"
      data-testid="no-contributing-tasks"
    >
      <span>{{ t('no_contributing_tasks') }}</span>
    </div>
    <v-row
      v-for="task of tasks"
      v-else
      :key="task.id"
      class="table-row"
    >
      <v-col class="text-neutral-darken1 property-name">
        <div
          class="d-flex items-center align-center"
        >
          <PriorityIcon
            :priority="task.priority"
            :size="14"
          />
          <span
            :class="[`text-subtitle-2 tw-font-medium d-block ml-3`, `text-${getPriorityColor(task.priority)}-darken2`]"
          >
            {{ task.title }}
          </span>
        </div>
      </v-col>
      <v-col
        class="text-neutral-darken1 property-name tw-text-right"
      >
        <v-chip
          :color="getStatusColor(task.status)"
          :class="['tw-h-[24px] tw-mt-4 tw-capitalize text-subtitle-1 tw-font-medium mr-auto my-auto', getStatusTextColor(task.status)]"
          :style="{'border': `1px solid rgb(var(--v-theme-${getStatusBorderColor(task.status)})) !important`}"
        >
          {{ t(`checklist.status.${task.status}`) }}
        </v-chip>
        <v-btn
          class="tw-ml-4"
          color="cta"
          size="24"
          target="_blank"
          data-testid="task-link"
          :to="{
            name: 'optimization-checklist',
            params: {
              project: appStore.projectId
            },
            query: {
              task: task.id,
              plot: 'true' // or undefined based on what will be set as a deep link
            }
          }"
        >
          <v-icon
            size="14"
            color="neutral-darken3"
          >
            fa:far fa-arrow-up-right
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="sass" scoped>
.table-row
  display: flex
  justify-content: space-between
  padding: 0 16px
  .property-name
    font-size: 14px
    font-weight: 400
    padding: 8px
.font-size-12
  font-size: 12px
.saving-tasks
  border-top: 1px solid rgb(var(--v-theme-neutral-lighten1))
.no-tasks
  font-size: 14px
  color: rgb(var(--v-theme-neutral-darken3))
</style>

<i18n lang="json" locale="de">
  {
    "contributing_tasks": "Zugehörige Aufgaben",
    "no_contributing_tasks": "Derzeit sind keine Aufgaben an diese Einsparung geknüpft."
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "contributing_tasks": "Contributing Tasks",
    "no_contributing_tasks": "There are currently no tasks associated with this saving."
  }
</i18n>
