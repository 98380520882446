<script setup lang="ts">
import SummarizedTable from '@/components/SummarizedTable.vue'
import { Alignment, ColumnHeader, RowData, SortDirection, Width } from '@/components/SummarizedContent/types'
import type { Keys } from '@/stores/views/Savings'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { getAnalysisIconAndColor } from '@/stores/views/Savings'

defineProps<{
  loading: boolean
  rows: RowData<Keys>[]
}>()

const emit = defineEmits<{
  // TODO: Add the correct type for the data
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (e: 'click-saving', data: any): void
}>()

const { t } = useI18n()

const headers = computed<ColumnHeader<Keys>[]>(() => [{
  key: 'analysis',
  text: t('analysis'),
  width: Width.Grow,
}, {
  key: 'component',
  text: t('component'),
  sortOptions: [SortDirection.ASC, SortDirection.DESC],
  width: Width.Grow,
}, {
  align: Alignment.Right,
  key: 'cost',
  text: t('costs'),
  tooltip: t('costs_tooltip'),
  unit: '€',
  width: Width.MinContent,
}, {
  align: Alignment.Right,
  key: 'co2',
  sortOptions: [SortDirection.DESC, SortDirection.ASC],
  text: t('co2'),
  tooltip: t('co2_tooltip'),
  width: Width.MaxContent,
},
{
  align: Alignment.Right,
  key: 'energy',
  sortOptions: [SortDirection.DESC, SortDirection.ASC],
  text: t('energy'),
  tooltip: t('energy_tooltip'),
  width: Width.MaxContent,
}])

function showSaving (data: RowData) {
  emit('click-saving', data)
}
</script>

<template>
  <SummarizedTable
    v-if="!loading"
    :rows="rows"
    :headers="headers"
    :sticky-headers="false"
    external-sorting
    selectable
    @select-row="showSaving"
  >
    <template #cell.analysis="{row}">
      <div :class="[`tw-flex tw-justify-center tw-items-center`]">
        <div
          :class="['tw-flex tw-items-center tw-justify-center tw-size-6 tw-mr-2 tw-rounded tw-border']"
          :style="getAnalysisIconAndColor(row.analysis?.custom.state).cssStyle"
        >
          <v-icon
            size="14"
          >
            {{ getAnalysisIconAndColor(row.analysis?.custom.state).icon }}
          </v-icon>
        </div>
        <span
          :style="{ color: getAnalysisIconAndColor(row.analysis?.custom.state).cssStyle.color}"
          class="text-body-2"
        >{{ row.analysis.text }}</span>
      </div>
    </template>
  </SummarizedTable>
  <div
    v-else-if="loading"
    data-testid="summarized-table-skeleton"
    class="mt-n4"
  >
    <v-row
      class="d-flex flex-row justify-start content-start mr-2"
    >
      <v-col
        v-for="(index) in 6"
        :key="index"
        cols="2"
      >
        <v-skeleton-loader
          type="text"
          class="mr-auto bg-transparent"
          width="80%"
        />
      </v-col>
    </v-row>
    <v-sheet
      v-for="i in 20"
      :key="i"
    >
      <v-skeleton-loader
        type="table-row"
        class="px-4 summarized-table__skeleton-loader"
      />
    </v-sheet>
  </div>
</template>

<style scoped>

</style>

<i18n lang="json" locale="de">
  {
    "analysis": "Analyse",
    "component": "Komponente",
    "costs": "Kosten",
    "costs_tooltip": "Kosteneinsparung · Jährlich",
    "co2": "CO₂",
    "co2_tooltip": "CO₂-Emissionseinsparung · Jährlich",
    "energy": "Energie",
    "energy_tooltip": "Energieeinsparung · Jährlich"
  }
</i18n>

<i18n lang="json" locale="en">
 {
  "analysis": "Analysis",
  "component": "Component",
  "costs": "Costs",
  "costs_tooltip": "Cost saving · Yearly",
  "co2": "CO₂",
  "co2_tooltip": "CO₂ emissions saving · Yearly",
  "energy": "Energy",
  "energy_tooltip": "Energy saving · Yearly"
 }
</i18n>
