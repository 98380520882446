<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { getAnalysisIconAndColor } from '@/stores/views/Savings'

defineProps<{
  // TODO: Add type for saving
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saving: any
}>()

const { t } = useI18n()

</script>

<template>
  <v-container class="tw-flex tw-flex-col">
    <v-row class="table-row">
      <v-col class="text-neutral-darken1 property-name">
        Savings
      </v-col>
      <v-col
        class="text-neutral-darken1 property-name tw-text-right"
      >
        Cost
      </v-col>
      <v-col
        class="text-neutral-darken1 property-name tw-text-right"
      >
        CO₂
      </v-col>
      <v-col
        class="text-neutral-darken1 property-name tw-text-right"
      >
        Energy
      </v-col>
    </v-row>
    <v-row class="table-row mt-0">
      <v-col class="text-neutral-darken1 property-value">
        <v-icon
          class="mr-2"
          size="14"
          :color="getAnalysisIconAndColor(saving.analysis?.custom.state).cssStyle.color"
        >
          {{ getAnalysisIconAndColor(saving.analysis?.custom.state).icon }}
        </v-icon>
        <span :style="{ color: getAnalysisIconAndColor(saving.analysis?.custom.state).cssStyle.color}">{{ saving.analysis?.custom.state }}</span>
      </v-col>
      <v-col
        class="text-neutral-darken1 property-value tw-text-right"
      >
        <div
          :class="['tw-rounded tw-border tw-px-2 tw-float-right']"
          width="fit-content"
          :style="getAnalysisIconAndColor(saving.analysis?.custom.state).cssStyle"
        >
          <span>
            {{ saving.cost.text }} {{ saving.cost.unit }}
          </span>
        </div>
      </v-col>
      <v-col
        class="text-neutral-darken1 property-value tw-text-right"
      >
        <div
          :class="['tw-rounded tw-border tw-px-2 tw-float-right']"
          width="fit-content"
          :style="getAnalysisIconAndColor(saving.analysis?.custom.state).cssStyle"
        >
          <span>
            {{ saving.co2.text }} {{ saving.co2.unit }}
          </span>
        </div>
      </v-col>
      <v-col
        class="text-neutral-darken1 property-value tw-text-right"
      >
        <div
          :class="['tw-rounded tw-border tw-px-2 tw-float-right']"
          width="fit-content"
          :style="getAnalysisIconAndColor(saving.analysis?.custom.state).cssStyle"
        >
          <span>
            {{ saving.energy.text }} {{ saving.energy.unit }}
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row class="table-row">
      <v-col
        class="text-neutral-darken1 font-size-12"
      >
        {{ t('brief_description') }}
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="sass" scoped>
.table-row
  display: flex
  justify-content: space-between
  padding: 0 16px
  .property-name, .property-value
    font-size: 14px
    font-weight: 400
  .property-name
    padding: 10px 8px
  .property-value
    padding: 8px
.font-size-12
  font-size: 12px
</style>

<i18n lang="json" locale="de">
{
  "brief_description": "Alle Einsparungen sind pro Jahr berechnet."
}
</i18n>
<i18n lang="json" locale="en">
{
  "brief_description": "All savings are calculated per year."
}
</i18n>
