{
  "locale_names": {
    "de": "German",
    "en": "English"
  },
  "asset_overview": {
    "extrapolated_data": "Extrapolation of the latest consumption data"
  },
  "actions": {
    "cancel": "Cancel",
    "ok": "Ok",
    "save": "Save",
    "close": "Close",
    "clear": "Clear",
    "back": "Back",
    "confirm": "Accept",
    "activated": "activated",
    "deactivated": "deactivated",
    "triggered": "triggered",
    "delete": "Delete",
    "remove": "Remove",
    "edit": "Edit",
    "more": "See more",
    "switch_on": "Switch on",
    "switch_off": "Switch off"
  },
  "dates": {
    "chart": {
      "date_format": "%m/%d/%Y, %H:%M:%S",
      "weekday_date_format": "%a, %m/%d"
    },
    "date_format": "MM/DD/YYYY",
    "datetime_format": "MM/DD/YYYY HH:mm:ss",
    "short_weekdays": {
      "monday": "Mon",
      "tuesday": "Tue",
      "wednesday": "Wed",
      "thursday": "Thu",
      "friday": "Fri",
      "saturday": "Sat",
      "sunday": "Sun"
    },
    "time_period": "Time period",
    "monthly": "Monthly",
    "yearly": "Yearly"
  },
  "errors": {
    "email_already_exists": "There is already a user with this email address.",
    "is_invalid": "{field} is invalid.",
    "is_required": "{field} is required.",
    "is_negative": "{field} must be a positive number.",
    "is_not_a_number": "{field} must be a number.",
    "does_not_respect_password_rules": "{field} must consist of at least 12 and at most 128 characters, including at least one digit, one upper and lower case letter, and one special character."
  },
  "form_fields": {
    "address": "Address",
    "assignee": "Assignee",
    "description": "Description",
    "email": "Email",
    "first_name": "First name",
    "last_name": "Last name",
    "name": "Name",
    "password": "Password",
    "phone": "Phone",
    "language": "Language",
    "unit_systems": "Unit System",
    "currency_systems": "Currency"
  },
  "highcharts": {
    "binary_values": "Binary values",
    "binary_no_data": "All binary values are 0",
    "loading": "Loading Data",
    "no_data": "No data available for the visualization",
    "reset_zoom": "Reset zoom",
    "values": "Values"
  },
  "links": {
    "meta": {
      "title": {
        "forwarding": "Forwarding",
        "forbidden": "Missing permissions",
        "auth_failed": "Authentication failed",
        "page_not_found": "Page Not Found",
        "user": {
          "profile": "Profile",
          "security": "Security"
        },
        "home": "Overview",
        "optimization": "Optimization",
        "optimization_checklist": "Checklist",
        "optimization_components": "Components",
        "optimization_recommendations": "Recommendations",
        "optimization_savings": "Savings",
        "data_points_view": "Datapoints",
        "status_and_alerts": "Status & Alerts",
        "asset_overview": "Asset overview",
        "asset_profile": "Profile",
        "ai_controls": "AI Controls",
        "ai_controls_app": "App",
        "ai_controls_log": "Log",
        "administration": "Administration",
        "manage_users": "Manage users",
        "data_privacy": "Data privacy",
        "cookie_list": "Cookies",
        "energy_and_co2": "Energy & CO₂",
        "reporting": "Reporting",
        "savings": "Savings",
        "meter": "Meter"
      }
    }
  },
  "topbar": {
    "links": {
      "my_account": "My Profile",
      "my_company": "My Company",
      "security": "Security",
      "settings": "Settings"
    }
  },
  "pagination": {
    "of": "of",
    "page": "Page"
  },
  "notifications": {
    "errors": {
      "fetch": "{resource} currently not available. Please try again in a few minutes. If the error persists, please contact the support.",
      "no_project_selected": "A project must be selected to perform this action. Aborting.",
      "no_project_selected_with_attempted_action": "A project must be selected {action}. Aborting.",
      "no_component_in_project": "Component could not be found in project.",
      "projects": {
        "loadProjects": "Failed to retrieve the projects. Please try again in a few minutes. If the error persists, contact the support."
      },
      "create": "{resource} could not be created. Please try again in a few minutes. If the error persists, please contact the support.",
      "delete": "{resource} could not be deleted. Please try again in a few minutes. If the error persists, please contact the support.",
      "update": "{resource} could not be updated. Please try again in a few minutes. If the error persists, please contact the support.",
      "clipboard": "Couldn’t copy \"{string}\" to the clipboard.",
      "user": {
        "assignRoles": "Failed to assign one or more roles to the user. If the error persists, please contact the support.",
        "emailNotUnique": "Couldn’t create user: another user with this email address already exists.",
        "updateAvatar": "Failed to upload the avatar. Please try again in a few minutes. If the error persists, please contact the support.",
        "deleteAvatar": "Failed to delete the avatar. Please try again in a few minutes. If the error persists, please contact the support.",
        "fetch": "Failed to retrieve your user data. Please try again in a few minutes. If the error persists, please contact the support.",
        "updateDetails": "Failed to update your details. Please try again in a few minutes. If the error persists, please contact the support.",
        "currentPasswordIncorrect": "Current password is incorrect.",
        "endpointLocked": "You tried to change your password too often. Please try again in a few minutes. If the error persists, please contact the support.",
        "updatePassword": "Failed to change the password. Please try again in a few minutes. If the error persists, please contact the support."
      },
      "endpoints": {
        "fetch": "Failed to retrieve important connection data. Please try again in a few minutes. If the error persists, please contact the support."
      },
      "datapoints": {
        "fetch": "Failed to retrieve the datapoints. Please try again in a few minutes. If the error persists, please contact the support.",
        "favorite": "Failed to update favorite datapoint. Please try again in a few minutes. If the error persists, please contact the support."
      },
      "data_points_view": {
        "fetchTimeseries": {
          "missing_arguments": "Could not fetch data due to missing arguments.",
          "error": "Could not load timeseries data."
        }
      },
      "tags": {
        "fetch": "Failed to retrieve the tags. Please try again in a few minutes. If the error persists, please contact the support.",
        "missing_arguments": "Could not fetch data due to missing arguments.",
        "remove": "Failed to delete tag. Please make sure this tag can be deleted or try again in a few minutes. If the error persists, please contact the support.",
        "add": "Failed to add tag. Please try again in a few minutes. If the error persists, please contact the support.",
        "update": "Failed to update tag. Please try again in a few minutes. If the error persists, please contact the support.",
        "duplicated": "Tag already exists - failed to create tag. Please remove it first or contact the support."
      },
      "descriptions": {
        "remove": "Failed to delete the description. Please make sure it can be deleted or try again in a few minutes. If the error persists, please contact the support.",
        "add": "Failed to add the description. Please try again in a few minutes. If the error persists, please contact the support.",
        "update": "Failed to update the description. Please try again in a few minutes. If the error persists, please contact the support.",
        "duplicated": "A description already exists. Please remove it first or contact the support."
      },
      "components": {
        "fetch": "Failed to retrieve the components. Please try again in a few minutes. If the error persists, please contact the support.",
        "create": "Failed to create new component. Please try again in a few minutes. If the error persists, please contact the support."
      },
      "users": {
        "fetch": "Failed to retrieve the users. Please try again in a few minutes. If the error persists, please contact the support."
      },
      "componentInProject": {
        "delete_component_in_project": "Failed to delete the component. Please try again in a few minutes. If the error persists, please contact the support.",
        "duplicated": "A component with this name already exists for this project. Please try another name.",
        "fetch": "Failed to retrieve the component. Please try again in a few minutes. If the error persists, please contact the support.",
        "update": "Failed to update component. Please try again in a few minutes. If the error persists, please contact the support."
      },
      "analysis_instances": {
        "fetch": "Analysis-Instances could not be loaded. Please try again in a few minutes. If the error persists, please contact the support."
      },
      "functions": {
        "fetch": "Analysis functions could not be loaded. Please try again in a few minutes. If the error persists, please contact the support.",
        "toggle_instance": "The analysis function could not be {action}. If the error persists, please contact the support."
      },
      "assetOverview": {
        "fetch": "Failed to retrieve building information. If the error persists, please contact the support."
      },
      "mapping": {
        "mapped": "The chosen datapoint has already been mapped to the component. Please adjust the mapping or choose a different one."
      },
      "optimization": {
        "toggle": "The optimization could not be {action}. If the problem persists, please contact the support."
      },
      "download": "Download for {resource} failed. Please retry."
    },
    "info": {
      "analysis_running": "The KPI calculation for the added meter values has been started."
    },
    "success": {
      "create": "{resource} created successfully.",
      "delete": "{resource} deleted successfully.",
      "update": "{resource} updated successfully.",
      "clipboard": "Copied \"{string}\" to the clipboard.",
      "user": {
        "updatePassword": "Successfully changed your password.",
        "updateDetails": "Successfully updated your profile."
      },
      "tags": {
        "add": "New tag added successfully.",
        "remove": "Tag removed successfully.",
        "update": "Tag updated successfully."
      },
      "descriptions": {
        "add": "Description added successfully.",
        "remove": "Description removed successfully.",
        "update": "Description updated successfully."
      },
      "components": {
        "create": "Component created successfully."
      },
      "componentInProject": {
        "delete": "Component deleted successfully.",
        "update": "Component updated successfully."
      },
      "functions": {
        "toggle_instance": "Analysis function {analysisFunction} {action}."
      },
      "setpoint": {
        "write": "Setpoint requested successfully",
        "release": "Setpoint released successfully"
      },
      "analysis_completed": "Analysis has completed successfully."
    },
    "resources": {
      "alerts": "Alerts",
      "attribute_definition": "Attribute Definition",
      "digital_twin": "Digital twin of the project",
      "component_data": "Component data",
      "component": "Component",
      "comment": "Comment",
      "comments": "Comments",
      "analysis_result": "Analysis result",
      "analysis_results": "Analysis results",
      "faults_data": "Faults data",
      "incident_alerts": "Incidents",
      "datapoint": "Datapoint",
      "company": "Company",
      "avatar": "Avatar",
      "company_profile": "Company profile",
      "label_definitions": "Labels",
      "label_systems": "Unit systems",
      "user": "User",
      "files": "Files",
      "assets": "Assets",
      "reading": "Reading",
      "savings_potential": "Savings potential",
      "meters": "Meters",
      "controls_apps_algorithms": "Controls algorithms",
      "controls_app": "Controls app",
      "controls_apps": "Controls apps",
      "controls_app_logs": "Controls app logs",
      "tasks": "Tasks",
      "task": "Task",
      "checklist_report": "Checklist Report"
    }
  },
  "administration": {
    "administrator": "Admin"
  },
  "data_points_view": {
    "quick_filters": {
      "favorites": "Favorites",
      "writable": "Writable"
    },
    "tags": {
      "writable": "Writable",
      "yes": "Yes"
    },
    "select_datapoint": "Select a datapoint."
  },
  "analysis_titles": {
    "technical_availability_analysis": "Technical availability",
    "co2_emission_analysis": "CO₂ emissions",
    "energy_cost_analysis": "Energy costs",
    "occupancy_rate_analysis": "Space utilization",
    "wellbeing_analysis": "Wellbeing",
    "fallback_title": "Building KPI",
    "incidents": "Incidents",
    "energy_consumption_analysis": "Energy consumption"
  },
  "unit_systems": {
    "si": "International System of Units (SI)",
    "usc": "US customary units (USC)",
    "metric": "Metric",
    "imperial": "Imperial units"
  },
  "status_and_alerts": {
    "messages": {
      "ready_for_operation": "Available",
      "in_operation": "In operation",
      "equipment_error_action_required": "Critical error",
      "equipment_error_less_important": "Error",
      "non_critical_notification": "Notification",
      "equipment_switched_off": "Switched off"
    }
  },
  "messages": {
    "no_data": "No data available"
  },
  "attributes": {
    "B++ADDRESS": "Location of building",
    "B++DESCRIPTION": "Description",
    "B++NAME": "Name of building",
    "B_COUNT_CAR_MAX": "Car park capacity",
    "B_COUNT_PEO_MAX": "Occupancy capacity",
    "B_GFA_AV": "Mean gross floor area",
    "B+AHU": "Air handling units",
    "B+ASSET_MANAGER": "Asset manager",
    "B+ASSET_OWNER": "Asset owner",
    "B+BA_BRAND": "Building automation brand",
    "B+BA_CNSTR": "Building automation constructor",
    "B+BA_PROT": "Building automation protocol",
    "B+BMS_BRAND": "Building management system brand",
    "B+BUILD_YEAR": "Year of construction",
    "B+CERTS_BREEAM": "BREEAM",
    "B+CERTS_DGNB": "DGNB",
    "B+CERTS_ECORE": "Ecore",
    "B+CERTS_ESTAR": "EnergyStar",
    "B+CERTS_GRESB": "GRESB",
    "B+CERTS_LEED": "LEED",
    "B+CERTS_WSCORE": "WiredScore",
    "B+CS": "Cooling system",
    "B+DP_COUNT": "Number of datapoints",
    "B+FACILITY_MANAGER": "Facility manager",
    "B+HS": "Heating system",
    "B+ICT": "ICT contact person",
    "B+M": "Meters",
    "B+NFA": "Net floor area",
    "B+PROP_MANA": "Property management",
    "B+RENOV_YEAR": "Year of the last renovation",
    "B+TYP": "Type",
    "update_error": "Value could not be saved, please try again."
  },
  "building_type": {
    "office": "Office",
    "retail": "Retail"
  },
  "certification_grades": {
    "platinum": "Platinum",
    "gold": "Gold",
    "silver": "Silver",
    "bronze": "Bronze",
    "certified": "Certified"
  },
  "support_call_to_action": {
    "support": "Support"
  },
  "optimizations": {
    "high_potential": "High potential",
    "high_potentials": "High potentials",
    "medium_potential": "Medium potential",
    "medium_potentials": "Medium potentials"
  },
  "ai_controls": {
    "algorithm": {
      "ara": {
        "name": "Adaptive Room Temperature Algorithm",
        "description": "<p>Changes room temperature setpoints to achieve predictive demand-driven operation for an optimal thermal comfort.</p>\n<p>Rooms should be comfortable for the duration that they are occupied to maximize user comfort, but not during off-times to save energy. However, when setting the occupancy schedule, the room conditioning will not start until the start of the occupancy, resulting in a uncomfortable first hour.</p>\n<p>The ARA algorithm extends the setpoint schedules dynamically, so that the comfort conditions are met on arrival of the occupant, but the overall pre-conditioning phase is minimized.</p>"
      },
      "bee": {
        "name": "Blind Controls Algorithm"
      },
      "custom": {
        "name": "Custom"
      },
      "dodo": {
        "name": "Decentralized occupancy driven optimisation"
      },
      "frog": {
        "name": "Free-Cooling Algorithm",
        "description": "<p>Implements a free cooling by an air handling unit, prioritizing user comfort by avoiding the inconvenience of uncomfortably cold offices in the morning.</p>\n<p>Buildings often apply their cooling systems during the day to maintain a comfortable temperature, while the cool air at night can be used to lower the buildings' temperature.</p>\n<p>Free cooling is selectively activated, triggered when running fans for nighttime cooling is more cost-effective than using compression chillers during the day for similar cooling. This logic optimizes comfort and maximizes energy savings, offering a practical, environmentally conscious solution for building cooling needs. The FROG algorithm is ideal for buildings which are too warm in summer.</p>"
      },
      "heating_curve": {
        "name": "Heating Curve",
        "description": "<p>Dynamically adjusts flow temperature setpoints in real-time based on outdoor temperature conditions optimizing energy consumption and maintaining indoor comfort.</p>\n<p>Heating and cooling needs fluctuate with outdoor conditions, while static setpoints result in both energy inefficiencies and suboptimal comfort levels.</p>\n<p>The Heating Curve algorithm sets indoor temperatures based on outdoor conditions, ensuring seamless adjustment as weather changes. Nighttime reductions can further save energy by lowering supply temperatures when schedules are inactive. Predictive control adjusts temperatures based on weather forecasts, particularly benefiting systems with high thermal inertia like underfloor heating, optimizing efficiency and preventing overshooting setpoints.</p>"
      },
      "hero": {
        "name": "Heat Recovery Optimization",
        "description": "<p>Determines the most efficient temperature for an air handling unit which can be used to heat or cool a building.</p>\n<p>Many air handling units have a fixed supply or extract air temperature setpoint. This setting leads to low heat recovery utilization or to heat losses because the air handling unit is cooling while heat is being supplied by radiators in the rooms.</p>\n<p>The supply air temperature is indirectly used to control the extract air temperature: If the extract air temperature is too low the supply air temperature is raised, vice versa. This is continued until the heat recovery is fully utilized, ideally, without additional active heating and cooling.</p>"
      },
      "mpc": {
        "name": "Supervisory model predictive control"
      },
      "orc": {
        "name": "Optimized Room Climate Controller"
      },
      "prawn": {
        "name": "Predictive heating curve weather compensation"
      },
      "schedule": {
        "name": "Schedules",
        "description": "<p>Switches a unit on/off based on a pre-defined fixed time schedule to save energy during times they are not required.</p>\n<p>Many HVAC systems often run continuously or operate on inefficient schedules, despite their requirement for limited operational periods.</p>\n<p>The Schedule algorithm controls when a system starts and stops according to set times. By default, it uses simple on/off commands (0 and 1). However, for systems that require different settings, like those with multiple operational states, the values for starting and stopping can be customized as needed.</p>\n\n"
      },
      "wasp": {
        "name": "Weather-predictive Temperation-Mode Setter",
        "description": "<p>Optimizes high inertia heating-cooling systems using weather predictions. It adjusts thresholds based on 48-hour forecasts, preventing rapid heating-cooling cycles for better efficiency.</p>\n<p>Systems with high thermal inertia, designed for heating and cooling, can paradoxically cycle rapidly between these functions. For example, a thermally activated concrete core might heat when the temperature drops below 18 °C and cool when it rises above 21 °C, resulting in cycles occurring nearly 100 days annually, making the system operate inefficient.</p>\n<p>WASP algorithm uses 48-hour ambient temperature predictions during working hours (8h–18h) to decide between heating or cooling, maintaining this decision for 24 hours before reassessment. It also defaults to nighttime reduction, limiting activity during off-hours except in extreme conditions.</p>"
      }
    }
  },
  "checklist": {
    "status": {
      "open": "Open",
      "doing": "Doing",
      "review": "Review",
      "done": "Done",
      "rejected": "Rejected",
      "failed": "Failed"
    },
    "priority": {
      "high": "High priority",
      "medium": "Medium priority",
      "low": "Low priority"
    },
    "headers": {
      "task_text": "Task",
      "task_tooltip": "Description of the task",
      "component_text": "Component",
      "costs_text": "Cost savings pot.",
      "costs_tooltip": "Cost saving potential (annually)",
      "created": "Start date",
      "status_text": "Status",
      "status_tooltip": "Task status"
    },
    "download_report_info": "Your report is being generated. The download will begin shortly ..."
  },
  "abbreviations": {
    "yearly": "Y"
  },
  "incidents": {
    "info": "All {alarms} that have been set up are displayed as incidents. The green signal color is displayed when there are no alarms. Yellow is displayed when alarms have already been processed. Red indicates open alarms. Please {contact_us} for setting up new alarms.",
    "links": {
      "alarms": "alarms",
      "contact_us": "contact us"
    }
  },
  "samplerate_auto": "Automatic",
  "samplerate_1h": "1 hour",
  "samplerate_15m": "15 minutes",
  "field_requirements": {
    "password": {
      "casing": "one lowercase and one uppercase letter",
      "digit": "one digit",
      "intro": "The {field} must consist of:",
      "number_of_characters": "between 12 and 128 characters",
      "special": "one special character"
    }
  },
  "meter_view": {
    "add_meter_data": "Add meter data",
    "delete_meter_confirmation_text": "Should the meter really be deleted?",
    "delete_meter_confirmation_title": "Delete meter",
    "enter_meter_reading": "Please enter the meter readings so we can calculate the consumption.",
    "total": "Total",
    "tooltip_text": "To ensure comparability between projects, only main counters are summed up here. The list may also contain submeters, which are marked separately.",
    "number_of_meters": "{count} meters",
    "submeter": "Submeter"
  },
  "savings_potential": {
    "details": "Details",
    "filter_by_component": "Filter analyses by component"
  },
  "kpis": {
    "energy_cost": "Energy costs",
    "energy_consumption": "Energy consumption",
    "co2_emissions": "CO₂ emissions",
    "productivity": "Wellbeing",
    "technical_availability": "Technical availability"
  },
  "recently": "some minutes",
  "for": "since",
  "date_ranges": {
    "MMM D": "MMM D",
    "YYYY MMM D": "YYYY MMM D",
    "D": "D"
  }
}
