import { RowData } from '@/components/SummarizedContent/types'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed } from 'vue'

export type Keys = 'analysis' | 'component'| 'cost' | 'co2' | 'energy'

// TODO: use the following contexts to fetch the data from the backend
/*
const FINANCIAL_KPI_CONTEXT = 'financial_absolute_realized'
const CO2_KPI_CONTEXT = 'co2_emissions_absolute_realized'
const ENERGY_KPI_CONTEXT = 'energy_consumption_absolute_realized'
*/

export const useSavingsStore = defineStore('savings', () => {
  const savingsTableRows = computed<RowData<Keys>[]>(() => [
    {
      analysis: {
        custom: {
          function_id: '7',
          state: 'realized',
        },
        text: 'Temperature Adjustment',
      },
      co2: {
        text: '22.500',
        unit: 'kg',
      },
      component: {
        component_id: '13',
        text: 'Heating Circuit 1',
        subtitle: 'Heating circuit',
      },
      cost: {
        text: '1.500',
        unit: '€',
      },
      energy: {
        text: '5.000',
        unit: 'kWh',
      },
    },
    {
      analysis: {
        custom: {
          function_id: '2',
          state: 'partiallyRealized',
        },
        text: 'Temperature Adjustment',
      },
      co2: {
        text: '22.500',
        unit: 'kg',
      },
      component: {
        component_id: '2',
        text: 'Heating Circuit 1',
        subtitle: 'Heating circuit',
      },
      cost: {
        text: '1.500',
        unit: '€',
      },
      energy: {
        text: '5.000',
        unit: 'kWh',
      },
    },
    {
      analysis: {
        custom: {
          function_id: '3',
          state: 'open',
        },
        text: 'Temperature Adjustment',
      },
      co2: {
        text: '22.500',
        unit: 'kg',
      },
      component: {
        component_id: '3',
        text: 'Heating Circuit 1',
        subtitle: 'Heating circuit',
      },
      cost: {
        text: '1.500',
        unit: '€',
      },
      energy: {
        text: '5.000',
        unit: 'kWh',
      },
    },
  ])

  return {
    savingsTableRows,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSavingsStore, import.meta.hot))
}

type SavingsState = 'realized' | 'partiallyRealized' | 'open'
type AnalysisIconStyle = {
  icon: string
  cssStyle: {
    color: string
    backgroundColor: string
    borderColor: string
  }
}

export function getAnalysisIconAndColor (savingsState: SavingsState): AnalysisIconStyle {
  switch (savingsState) {
    case 'realized':
      return {
        icon: 'fa:far fa-check',
        cssStyle: {
          color: 'rgb(var(--v-theme-success-darken2))',
          backgroundColor: 'rgb(var(--v-theme-success-lighten4))',
          borderColor: 'rgb(var(--v-theme-success-lighten3))',
        },
      }
    case 'partiallyRealized':
      return {
        icon: 'fa:far fa-circle-half-stroke',
        cssStyle: {
          color: 'rgb(var(--v-theme-info-darken2))',
          backgroundColor: 'rgb(var(--v-theme-info-lighten4))',
          borderColor: 'rgb(var(--v-theme-info-lighten3))',
        },
      }
    case 'open':
      return {
        icon: 'fa:far fa-circle',
        cssStyle: {
          color: 'rgb(var(--v-theme-info-darken2))',
          backgroundColor: 'rgb(var(--v-theme-info-lighten4))',
          borderColor: 'rgb(var(--v-theme-info-lighten3))',
        },
      }
  }
}
